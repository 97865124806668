@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Urbanist', sans-serif;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --color-Primary-Text: #171717;
  --color-Ternary-Text: #747475;
  --color-Secondary-Text: #454545;
  --color-Primary-Button: #fdba12;
  --color-Primary-BHover: #e4a810;
  --color-white: #ffffff;
  --color-black: #000000;
  --stroke-primary: #d9d9d9;
  --stroke-border: #e4e5ea;
  --stroke-secondary: #8a8370;
  --light-bg: #fffceb;
  --Bg-Secondary: #f1f1f1;
}

/* LOADER */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.animate-spinner {
  animation: spinner 1s linear infinite;
}
.my-custom-captcha {
  height: 43px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../src/assets/images/CapchaBack.png');
  border-radius: 16px;
}

.answerEngage {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease, max-height 0.4s ease; /* Adjust the duration and timing function as needed */
}

/* Open state */
.answerEngage.open {
  opacity: 1;
  max-height: 70px; /* Adjust the value as needed */
}
@keyframes bubbleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.integration-shadow {
  box-shadow: 0px 0px 150px 0px #dfe4ec;
  background-color: var(--color-white);
  animation: bubbleAnimation 3s infinite;
  padding: 20px;
  border-radius: 100%;
}
.bubble1 {
  animation-delay: 0s;
}

.bubble2 {
  animation-delay: 0.5s;
}

.bubble3 {
  animation-delay: 1s;
}

.bubble4 {
  animation-delay: 1.5s;
}

.bubble5 {
  animation-delay: 2s;
}

/* FAQ */
.answerSection {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease, max-height 0.4s ease; /* Adjust the duration and timing function as needed */
}

/* Open state */
.answerSection.open {
  opacity: 1;
  max-height: 200px; /* Adjust the value as needed */
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}


/* SWITCH */
.cookie-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.underlineSpan{
  color: var(--color-Primary-Button) !important;
  text-decoration: underline;
  cursor: pointer;
}
.boldfont {
  font-weight: 650;
  color: var(--color-Secondary-Text);
}




@media (max-width: 980px) {
  .answerEngage.open {
    max-height: fit-content;
  }
}
@media (max-width: 600px) {
  .integration-shadow {
    padding: 12px;
  }

  .my-custom-captcha {
    width: 200px;
    height: 40px;
  }
}
@layer components {
  .FLEX_CENTER {
    @apply flex items-center justify-center;
  }
  .headingText {
    @apply text-headingW font-UrbanistFont font-bold leading-[96px] max_xl:text-[70px] max_xl:leading-[88px] max_md:text-[60px] max_md:leading-[72px] max_sm:text-subHeadingW max_sm:leading-[48px];
  }
  .Text60 {
    @apply text-[60px] font-UrbanistFont text-PrimaryT font-semibold leading-[72px] max_md:text-subHeadingW max_md:leading-[48px] max_sm:text-text3Xl max_sm:leading-[36px];
  }
  .Text20 {
    @apply text-textMedium font-UrbanistFont font-normal text-TernaryT max_sm:text-textSmall;
  }
  .Text24 {
    @apply text-text2Xl font-UrbanistFont font-semibold max_md:text-textMedium max_md:leading-[24px] max_sm:text-textNormal max_sm:leading-[21px];
  }
  .Text16Normal {
    @apply text-textSmall font-UrbanistFont font-normal text-TernaryT max_sm:text-textExtraSmall;
  }
  .Text40 {
    @apply text-subHeadingW font-UrbanistFont font-semibold leading-[48px] max_md:text-textLarge max_md:leading-[38px] max_sm:text-text2Xl max_sm:leading-[28.16px];
  }
  .Text18Normal{
    @apply text-textNormal font-UrbanistFont font-semibold text-PrimaryT
  }
  .Text24Legal{
    @apply text-text2Xl font-UrbanistFont font-semibold
    max_md:text-[22px] max_sm:text-textMedium max_md:leading-[26px] max_sm:leading-6
  }
  .Text16L {
    @apply text-textSmall font-UrbanistFont font-[450] text-TernaryT leading-[30px] max_sm:leading-6;
  }
  .labelClass {
    @apply text-textExtraSmall font-UrbanistFont font-semibold text-PrimaryT text-start w-full uppercase mb-[3px];
  }
  .text14{
    @apply text-textExtraSmall font-UrbanistFont font-semibold text-PrimaryT
  }
}
